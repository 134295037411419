import React from "react"
import { graphql, Link } from "gatsby"

import Img from "gatsby-image"

import SmoothScrollbar from "../components/scrollbar"
import SEO from "../components/seo"

import "../styles/projects.scss"

const ProjectsPage = ({ data }) => {
  const circularText = {
    container: {
      width: "1000",
      height: "1000",
    },
    radius: "300",
  }
  return (
    <>
      <SEO title="Projects" />
      <SmoothScrollbar>
        <section className="projects-page-wrapper">
          <div className="projects-page-inner">
            {data.allContentfulProject.nodes.map((item, index) => (
              <div className="projects-page-project-wrapper" key={index}>
                <div className="projects-page-link-wrapper">
                  <Link to={`/${item.slug}`} className="projects-page-link">
                    <div className="projects-page-title-wrapper">
                      <h2 className="projects-page-title">
                        <div className="projects-page-svg-wrapper">
                          <svg
                            viewBox={`0 0 ${circularText.container.width} ${circularText.container.width}`}
                            className="circular-svg"
                          >
                            <defs>
                              <path
                                id="curve"
                                d={` M ${circularText.container.width / 2}, ${
                                  circularText.container.height / 2
                                } m -${circularText.radius}, 0 a ${
                                  circularText.radius
                                },${circularText.radius} 0 1,1 ${
                                  circularText.radius * 2
                                },0 a ${circularText.radius},${
                                  circularText.radius
                                } 0 1,1 -${circularText.radius * 2},0`}
                              />
                            </defs>
                            <text>
                              <textPath href="#curve" textLength="200%">
                                {item.title} {item.title} {item.title}
                              </textPath>
                            </text>
                          </svg>
                        </div>
                      </h2>
                    </div>
                    <div className="projects-page-image-wrapper">
                      <Img
                        fluid={item.coverImage.fluid}
                        className="projects-page-image"
                        draggabble={false}
                        imgStyle={{
                          transition:
                            "all 1s cubic-bezier(0.4, 0.42, 0.12, 0.99)",
                        }}
                      />
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
      </SmoothScrollbar>
    </>
  )
}

export default ProjectsPage

export const query = graphql`
  query ProjectsPageQuery {
    allContentfulProject {
      nodes {
        coverImage {
          fluid(quality: 40, maxWidth: 1920) {
            base64
            aspectRatio
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        title
        slug
      }
    }
    portraitImage: file(relativePath: { eq: "portrait.png" }) {
      childImageSharp {
        fixed(width: 1920, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    backgroundImage: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        fixed(width: 1920, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
